import { useState, StrictMode } from "react";
import { render } from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import TestRequestEmpty from "./TestRequestEmpty";
import TestRequest from "./TestRequest";
import Header from "./Header";
import ThemeContext from "./ThemeContext";

const App = () => {
  const theme = useState("darkblue");
  return (
    <ThemeContext.Provider value={theme}>
      <Header />
      <div
        className="d-flex justify-content-center align-items-center m-sm-5"
        style={{ borderBottom: "15px solid #66C430" }}
      >
        <div className="col-12 content rounded p-0 bg-white">
          <Router>
            <Switch>
              <Route path="/verify/:id" component={TestRequest} />
              <Route path="/">
                <TestRequestEmpty />
              </Route>
              <Redirect to="/" />
            </Switch>
          </Router>
        </div>
      </div>
    </ThemeContext.Provider>
  );
};

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root")
);
