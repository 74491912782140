import { FunctionComponent, useState, useEffect } from "react";

const TestRequestEmpty: FunctionComponent = () => {
  const [timeLeft, setTimeLeft] = useState(5);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) {
      window.location.href = "https://app.freshtest.com.au";
      return;
    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  return (
    <>
      <div
        className="MuiPaper-root MuiAlert-root MuiAlert-filledInfo MuiPaper-elevation0"
        style={{ backgroundColor: "#e22d2d" }}
        role="alert"
      >
        <div className="MuiAlert-icon">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
            <circle cx="20" cy="20" r="20" fill="white"></circle>
            <path
              d="M20 10C14.48 10 10 14.48 10 20C10 25.52 14.48 30 20 30C25.52 30 30 25.52 30 20C30 14.48 25.52 10 20 10ZM21 25H19V23H21V25ZM21 21H19V15H21V21Z"
              fill="#e22d2d"
            ></path>
          </svg>
        </div>
        <div className="MuiAlert-message">
          <h6 className="ml-5 mt-3">
            You have scanned an invalid QR Code. Redirecting to portal in{" "}
            {timeLeft} seconds.
            <span id="timer"></span>
          </h6>
        </div>
      </div>
      <div
        className="text-left text-uppercase my-5 ml-5"
        style={{ fontSize: "1.5rem", fontWeight: 600 }}
      >
        Certificate Authentication Portal
      </div>
      <div className="test-result d-flex mx-5 my-1">
        <div className="col-lg-3 col-6 p-0">Certificate:</div>
        <div className="col-lg-9 col-6 p-0"></div>
      </div>
      <div className="test-result d-flex mx-5 my-1">
        <div className="col-lg-3 col-6 p-0">Test:</div>
        <div className="col-lg-9 col-6 p-0"></div>
      </div>
      <div className="test-result d-flex mx-5 my-1">
        <div className="col-lg-3 col-6 p-0">Batch Code:</div>
        <div className="col-lg-9 col-6 p-0"></div>
      </div>
      <div className="test-result d-flex mx-5 my-1">
        <div className="col-lg-3 col-6 p-0">Product:</div>
        <div className="col-lg-9 col-6 p-0"></div>
      </div>
      <div className="test-result d-flex mx-5 my-1">
        <div className="col-lg-3 col-6 p-0">Sampling Date:</div>
        <div className="col-lg-9 col-6 p-0"></div>
      </div>
      <div className="test-result d-flex mx-5 my-1">
        <div className="col-lg-3 col-6 p-0">Analysis Commenenced:</div>
        <div className="col-lg-9 col-6 p-0"></div>
      </div>
      <div className="test-result d-flex mx-5 my-1">
        <div className="col-lg-3 col-6 p-0">Issue Date:</div>
        <div className="col-lg-9 col-6 p-0"></div>
      </div>
      <div className="test-result d-flex mx-5 my-1">
        <div className="col-lg-3 col-6 p-0">Notes:</div>
        <div className="col-lg-9 col-6 p-0"></div>
      </div>
      <div className="test-result d-flex mx-5 my-4">
        <div className="col-2 p-0">Compound / Analyte</div>
        <div className="col-2 p-0">Limit of Reporting</div>
        <div className="col-lg-1 col-2 p-0">Units</div>
        <div className="col-lg-1 col-2 p-0">Result</div>
        <div className="col-lg-1 col-2 p-0">MRL</div>
        <div className="col-lg-1 col-2 p-0">% of MRL</div>
      </div>

      <div
        className="text-left ml-5"
        style={{ fontSize: "1.5rem", marginTop: 80, marginBottom: 120 }}
      >
        Refer to the NATA Accredited Laboratory Certificate for detailed
        analysis.
      </div>
    </>
  );
};

export default TestRequestEmpty;
