import { FunctionComponent, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import { TestRequest } from "./APIResponsesTypes";
import { formatLimit } from "./helpers";
import { ITestRequest } from "./types";
import { iconMap } from "./constants";

function isValidDate(d: number | Date) {
  return d instanceof Date && !isNaN(d);
}

export function formatDate(date: string | number | Date) {
  const d = new Date(date);
  if (isValidDate(d)) {
    let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    let month = d.getMonth() + 1;
    let monthWithNull = d.getMonth() < 10 ? "0" + month : month;
    return day + "/" + monthWithNull + "/" + d.getFullYear();
  }
  console.error("Not a valid Date");
}

const TestRequest: FunctionComponent = ({ match, location }) => {
  let history = useHistory();
  const id = match.params.id;
  const urlParams = new URLSearchParams(location.search);
  const [testRequest, setTestRequest] = useState<ITestRequest>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) return;
    void requestTestRequestById(urlParams.get("version"));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  async function requestTestRequestById(version: string | null) {
    setLoading(true);
    const res = await fetch(
      `https://api.app.freshtest.com.au/public/test_requests/verify/${id}/?version=${version}`
    );

    if (res.status === 500 || res.status === 404) {
      setLoading(false);
      history.push("/");
    } else {
      const json = await res.json();
      // console.log(json);
      setTestRequest(json.data);
      setLoading(false);
    }
  }

  return (
    <>
      <div
        className="text-center text-sm-left text-uppercase m-5"
        style={{ fontSize: "2rem", fontWeight: 600 }}
      >
        Certificate Authentication Portal
      </div>
      <div
        className="d-lg-block d-none"
        style={{
          position: "absolute",
          right: "11vw",
          top: 48,
          width: "18vw",
          maxWidth: "13vw",
        }}
      >
        <img
          style={{
            display: "block",
            maxWidth: "100%",
            width: "100%",
            height: "auto",
          }}
          src={
            iconMap[
              testRequest?.manual_verdict
                ? testRequest?.manual_verdict
                : testRequest?.verdict
            ]
          }
        />
      </div>
      <div className="d-flex justify-content-center d-block d-lg-none mb-5">
        <img
          style={{
            display: "block",
            minWidth: "15vw",
            width: "20vw",
            height: "auto",
          }}
          src={
            iconMap[
              testRequest?.manual_verdict
                ? testRequest?.manual_verdict
                : testRequest?.verdict
            ]
          }
        />
      </div>
      <div className="test-result d-flex mx-sm-5 mx-3 my-1">
        <div className="text-right text-lg-left col-lg-3 col-6 p-0 pr-4">
          Certificate:
        </div>
        <div className="col-lg-9 col-6 p-0">
          {loading
            ? "..."
            : testRequest?.code
            ? `CA${testRequest?.code.substring(2)}-${String(
                testRequest?.version
              )}`
            : "N/A"}
        </div>
      </div>
      <div className="test-result d-flex mx-sm-5 mx-3 my-1">
        <div className="text-right text-lg-left col-lg-3 col-6 p-0 pr-4">
          Test:
        </div>
        <div className="col-lg-9 col-6 p-0">
          {loading
            ? "..."
            : testRequest?.test.code
            ? testRequest?.test.code
            : "N/A"}
        </div>
      </div>

      <div className="test-result d-flex mx-sm-5 mx-3 my-1">
        <div className="text-right text-lg-left col-lg-3 col-6 p-0 pr-4">
          Batch Code:
        </div>
        <div className="col-lg-9 col-6 p-0">
          {loading ? "..." : testRequest?.code ? testRequest?.code : "N/A"}
        </div>
      </div>
      <div className="test-result d-flex mx-sm-5 mx-3 my-1">
        <div className="text-right text-lg-left col-lg-3 col-6 p-0 pr-4">
          Product:
        </div>
        <div className="col-lg-9 col-6 p-0">
          {loading
            ? "..."
            : testRequest?.crop?.name
            ? testRequest?.crop?.name
            : "N/A"}
        </div>
      </div>

      <div className="test-result d-flex mx-sm-5 mx-3 my-1">
        <div className="text-right text-lg-left col-lg-3 col-6 p-0 pr-4">
          Sampling Date:
        </div>
        <div className="col-lg-9 col-6 p-0">
          {loading
            ? "..."
            : testRequest?.submit_date
            ? formatDate(testRequest?.submit_date)
            : "N/A"}
        </div>
      </div>
      <div className="test-result d-flex mx-sm-5 mx-3 my-1">
        <div className="text-right text-lg-left col-lg-3 col-6 p-0 pr-4">
          Analysis Commenenced:
        </div>
        <div className="col-lg-9 col-6 p-0">
          {loading
            ? "..."
            : testRequest?.analysis_date
            ? formatDate(testRequest?.analysis_date)
            : "N/A"}
        </div>
      </div>
      <div className="test-result d-flex mx-sm-5 mx-3 my-1">
        <div className="text-right text-lg-left col-lg-3 col-6 p-0 pr-4">
          Issue Date:
        </div>
        <div className="col-lg-9 col-6 p-0">
          {loading
            ? "..."
            : testRequest?.result_date
            ? formatDate(testRequest?.result_date)
            : "N/A"}
        </div>
      </div>

      <div className="test-result d-flex mx-sm-5 mx-3 my-1">
        <div className="text-right text-lg-left col-lg-3 col-6 p-0 pr-4">
          Notes:
        </div>
        <div className="col-lg-9 col-6 p-0">
          {loading ? "..." : testRequest?.notes ? testRequest?.notes : "N/A"}
        </div>
      </div>
      <div
        className="test-result d-flex mx-3 mx-sm-5 my-4"
        style={{ borderBottom: "1px solid #c4c4c4" }}
      >
        <div className="align-self-center col-3">Compound / Analyte</div>
        <div className="align-self-center col-2 p-0">Limit of Reporting</div>
        <div className="align-self-center col-lg-1 col-2">Units</div>
        <div className="align-self-center col-lg-1 col-2">Result</div>
        <div className="align-self-center col-lg-1 col-1">
          {testRequest?.processed_results?.certificate_type.toLowerCase() ===
          "chemical"
            ? "MRL"
            : "ML"}
        </div>
        <div className="align-self-center col-lg-1 col-2 p-0 text-right">
          % of
          {testRequest?.processed_results?.certificate_type.toLowerCase() ===
          "chemical"
            ? " MRL"
            : " ML"}
        </div>
      </div>

      {testRequest?.processed_results?.filtered_results.map(
        ({
          id,
          analyte_name,
          lor,
          units,
          result_value_formatted,
          use_apvma,
          mrl,
          mrl_perc,
          mrl_apvma,
          mrl_apvma_perc,
        }) => {
          const current_mrl_perc = use_apvma ? mrl_apvma_perc : mrl_perc;

          return (
            <div key={id} className="test-result d-flex mx-sm-5 mx-3 my-1">
              <div className="col-3">{analyte_name}</div>
              <div className="col-2 p-0">{lor || "N/A"}</div>
              <div className="col-lg-1 col-2">{units || "N/A"}</div>
              <div className="col-lg-1 col-2">
                {result_value_formatted || "N/A"}
              </div>
              <div className="col-lg-1 col-1">
                {use_apvma ? mrl_apvma : mrl}
              </div>
              <div className="col-lg-1 col-2 p-0 text-right">
                {current_mrl_perc === "N/A"
                  ? current_mrl_perc
                  : formatLimit(parseFloat(current_mrl_perc))}
              </div>
            </div>
          );
        }
      )}

      <div
        className="text-center text-lg-left ml-lg-5"
        style={{ fontSize: "1.5rem", marginTop: 80, marginBottom: 120 }}
      >
        {!loading && testRequest?.processed_results?.all_below_lor && (
          <>
            All compounds / analytes in this sample were below the Limit if
            Reporting (LOR).
            <br />
          </>
        )}
        Refer to the NATA Accredited Laboratory Certificate for detailed
        analysis.
      </div>
    </>
  );
};

export default TestRequest;
