export function formatLimit(limit: number) {
  if (typeof limit !== "number") return;
  if (limit >= 0) {
    return `${limit.toFixed(2)} %`;
  } else return `(${limit.toFixed(2).slice(1)} %)`;
}

export const renderLimitPercent = (
  ResultValue: any,
  MRL_in: string | number,
  LOR_in: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  let result = (ResultValue || "").replace(`<`, "");
  const MRL = MRL_in;
  const LOR = LOR_in;
  if (result === "ND" && LOR === "ND" && MRL === "D") return formatLimit(0);
  if (
    (result === "D" || typeof result === "number") &&
    LOR === "ND" &&
    MRL === "D"
  )
    return formatLimit(100);
  result = result === "ND" ? 0 : Number(result);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if ((ResultValue || "").includes("<") && result === Number(LOR))
    return formatLimit(0);
  if (MRL !== 0 && !MRL) return;
  const value = result / Number(MRL);
  return value === 0 || value ? formatLimit(value * 100) : "Can't calculate";
};
